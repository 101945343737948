module.exports = {
  debug: true,
  env: "staging",
  rd: Boolean(process.env.REACT_APP_RD),
  api: {
    url: "https://mgmt-sys-stg.aagold-th.com",
    url_report: "https://mgmt-sys-stg.aagold-th.com",
  },
  client: {
    id: "VMayVhr7H3IJpTOEyPdKB4VbiD62xLp6uzSf5ZZa",
    secret: "VU5LCjEEBxmj6CqAQN3c4Ju4L6CoVBiWe4lQMTwRKnhiYChg85FgGO9ujNYFtWukMsf6Twmco6syO1xusWvXvvNbeE1HtfQBEaauz2JUdWmEG6JrPFP3mRzYExo1NbLf"
  },
  baseMobileUrl: 'https://m-stg.aagold-th.com/',
  link: {
    expense: "https://expenses-stg.aagold-th.com/"
  },
  url_genpdf: 'https://pos-stg.aagold-th.com/genpdf'
};